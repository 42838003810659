import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The client wanted a design for his project `}<a parentName="p" {...{
        "href": "https://mokens.io"
      }}>{`Mokens`}</a>{`, which are a digital creation that can be collected and can be viewed, held, transferred and received in crypto wallets.`}</p>
    <p>{`After a few emails with the client, I proposed the dark theme for the page, the colours used in the design came from the Mokens logo that was already created. The design has grown gradually with the client input and eventually, the light theme was created because the client wanted the website to have the same feeling like another one.`}</p>
    <p>{`Unfortunately, the client didn't want to go ahead with the full code of the website and decided to pay only for the design since he wasn't sure how he wanted the website to look like.`}</p>
    <h2 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h2>
    <p><strong parentName="p">{`Website:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://mokens.io"
      }}>{`https://mokens.io/`}</a></p>
    <p><strong parentName="p">{`GitHub repo:`}</strong>{` Design proposal - no repo`}</p>
    <h2 {...{
      "id": "images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#images",
        "aria-label": "images permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Images`}</h2>
    <p>{`Home Page - Dark Theme
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/65c7a604be563641eeccba717a86357a/ac7a9/mokens-dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "166.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAIAAABWXBxEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAELElEQVQ4y42VSXPiRhTHX2sB48FbYnsyNgM2i0ALi9CChIRAEmCwsVkMM8Zjz5KamlTNJZOq3HJLJVWp3JIPkFNOOSTnXPM1cswHIdVCyDBxJqn6CdTL/3W/f3U/ASnUd63TMFuHTPUDhLh6RLDDfJ1mazRnkbkapKsAjEHxdTJrQEqDtI6nzn+XSevwWIJDEZIVlDURYyDGwP34SWogNEDuAt/AzayJmQ8zWEnw9clX333/86/y5Wt4LBFZ0w86D0zkHVo9o5UuLXfCWo+Qu+DNwCscqZXnX/z251+z2eyzH36ChEJljSVxSouWWgl3Eq8PH+nnu0onzN1ZQGXNlHbxy+9/zGazV9/+CIdl8m5lHFsh7Sdrn39Dq12aq0JGx2Ys5RzlG9Xp2+mXX2fbU0jpKBjCZqQ0lDOJvI2YKsHoCItXyegQE+FhHhIyLI8C52BYG3IN//0+EO8SQhPx7ko/yEOQh0gZIWUE0gBTHoA0nPf/B97sflgbH5y8Ouh8umldhypjUl0E+jD4kQeUNv7Iud1vv9xzn+83X0SMpyD2/4dYHYI6wujDxbb7IPZJbUSoQy8FDFIwQTMQYyWhXW41n6HKJapcEtoYlNGmMyUrIygPkBcupI03G9dQ7qN/ikEdbdo3W/ZNtHEdsa5AGW40nu04t9v166h5haQBrV5+7NxGzauN2pQMrA3EIeMJVZ14i+MmpU/C5tOQNiG8qYQ8pCpjJA8JZYTkRYKgDHzkBX6zj8H5X0DZQ1xQXgCs5ZOrYfCLx7xzfr0wpoexgq/J1SihQecdyNUpoUHMYwUXILM4pO/d8+D2rhWbW+YIlTqRyjnN1f3Y3iTEN0CwgavjgpHSlsTzP6YKGWtNP4tNrmjpBNLG8gpIbBOVHlE5I+UOIXdgpRh4kLxNK70H1iAsn6FszS8pjAFpfUM62TUutrVeRO6Ey+27jIIqtV5wQ4JDsPa2fErh+qYHxiDWIlgL5Wq4mTV9m7ImrFS5eekLKiFjBHbez0Kp+WakF4ViWRmsdr+46EKpBaU2CM6dzwtNqOAg1kLsveK07vspd0m5S0kdVGrhnS/Em0Vnu9RcF1v3izfE1l7lbFfpRovuesGlWMtP2zsF4ZObSO8lLdj/sm2vRBPz3QaGzTPPVKnhG3r8FjKG73ZgwcIw471yu0Jcwiz3+Gc7xkNMCMVz9BEH8SIciZCU4ViClAJJBb8nZUirkFbwUKIIhzwcsHDIYSFxwMBOuvLudffdBPZ5lFYgb0NSpYtORGziMs5UqVITWBMdS3BcflCs70g2JPLwCQMQ4+CQezh4kxy8gCMJ5yN1gbOj0umeOVoTu0hwQ4UWZC2824JD2mP6ZIrKLjzKAcQESJSoI5FMqd7BsEBwId8mSqekdA6lHhS6kG8D7+KvAmNCUoVEGeIliOf/BigvMyeYpjWXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Home Page Dark Theme",
            "title": "Home Page Dark Theme",
            "src": "/static/65c7a604be563641eeccba717a86357a/0eb09/mokens-dark.png",
            "srcSet": ["/static/65c7a604be563641eeccba717a86357a/c59a0/mokens-dark.png 125w", "/static/65c7a604be563641eeccba717a86357a/86700/mokens-dark.png 250w", "/static/65c7a604be563641eeccba717a86357a/0eb09/mokens-dark.png 500w", "/static/65c7a604be563641eeccba717a86357a/e9985/mokens-dark.png 750w", "/static/65c7a604be563641eeccba717a86357a/1263b/mokens-dark.png 1000w", "/static/65c7a604be563641eeccba717a86357a/ac7a9/mokens-dark.png 1920w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Expanded Card Details - Dark Theme
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/febdf0c121ea04f83efae5e0c434ed5e/ac7a9/mokens-dark-card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/0lEQVQ4y3WTa1MSURjH/2fFEKwUxxsWeNl1WYFFVhYQBOSyu4GwgnnJa6NdERMUS5zSqelV1pte9A2a6TJTfYDe1Is+R5+lOSaOls785plzzj6/8+w5uw/aE7lm1zjhRgk3Cm4UbBBcCIMhGusw/NglZ+yIKI1DMcKPYTAM4koSUSMejfHcIKIGUYUrhaEEXElyDqm/AwyNUxnRNYSXyUihwT9NfNOMPI34PcONMlLr51GiUXmE8BJ9TZK4jxab19bx/uC+02YFYPBPdc/vW/LVq3q1dbLamq82ZyumTLkpvXnkF6FsILxIZYbK9pmI7/eXd9UFnQFh5KnmyR3zRNmY3jSoGw1qiZwp/o9s6XP39nx79Xh5Ig4wjG+qsbBrmal1z+xaCjumTJlRSoxSqu9ySkbsTotNFK7376/Nba/MSx6/MThnnt7rWNy3LTyzLzztorvUrLN77TcfN6glJItQT+TwbftiOf/ytbpzkK69XHr3piW/Aa3ckN0y6dvm7JZpomzOVszZijG9SZTS2crhFRx+MH791fbpR8vnn40fv5Olgyu5LctMzaxvI7lOSx3z35kxttq1UnHXdl3PXwhP9oRK1ZDZQKYCvUoUesgTzrkwxFaRKDL6dtP6oWF2D+MPaEZ6E5kylHM/9Wk5ukovIFVE/CESxQuEi2RPBlIOkg7/FOQ8RnTIBTr15engNP4CTZByNEfU6P8PPkKEKCNE4agjRMFHzkxPoCtHj/gIBVwANhlWH+0nNoiBANij9hoIHnfV8WKwTqAeA0A71zkyLCoSepywuTHoR78EVoYjhL4RymAQQgh9XthF0iMYrA6mk0UXi24OaGN77z669XYfvR6wQSJpcEQa3Ym2cP6ypIGPGL0qIybAh8AGiE/tyK2Yojr6hmF1ANfdZk/KMlow8CFwYXg1eFSDqF4N5k2yDrfGuFUyFIcQAz8GSUVgAj4VrA/XnECvl/RLZECmze2IQYjDpcCThjcHr07jcBpuFc4khHFwIdIvw+6l2MQ/r4nZcOCUaFgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Expanded Card Details Dark Theme",
            "title": "Expanded Card Details Dark Theme",
            "src": "/static/febdf0c121ea04f83efae5e0c434ed5e/0eb09/mokens-dark-card.png",
            "srcSet": ["/static/febdf0c121ea04f83efae5e0c434ed5e/c59a0/mokens-dark-card.png 125w", "/static/febdf0c121ea04f83efae5e0c434ed5e/86700/mokens-dark-card.png 250w", "/static/febdf0c121ea04f83efae5e0c434ed5e/0eb09/mokens-dark-card.png 500w", "/static/febdf0c121ea04f83efae5e0c434ed5e/e9985/mokens-dark-card.png 750w", "/static/febdf0c121ea04f83efae5e0c434ed5e/1263b/mokens-dark-card.png 1000w", "/static/febdf0c121ea04f83efae5e0c434ed5e/ac7a9/mokens-dark-card.png 1920w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Home Page - Light Theme
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2a029560ff59d44babc1ba086d2cb591/ac7a9/mokens-light.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "166.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAIAAABWXBxEAAAACXBIWXMAAAsTAAALEwEAmpwYAAADf0lEQVQ4y6VUTWwbRRSe/LQ9FCHOHOmlLUiA7cY9oIqqau0gVbGTFIEqQBAh9UBcbj1CG4kbREArkHLgQCJaDhAKRaIlSKgiAsSBH4kD5JCfqknsHdu7O7vz+2bQ7qy3XjtFtHx6h/fe7jfvzfsZNPvJlx8sfnPp8lfvLlydXbj6zvwXiSxk5P3L1y5eufbh5zcuffbt3OLSxU+/fnt+EZkY0hhh/g2cc2XM6k831q9/vLKy4rltYwziQigptwP2p0vWSLgZ0DUvWPODgHMhBBMROOjz781duX7z759v/rb40ctTr363tBSRAQC0FpQSjB0HN3ALt9oBZdCBBlAAy7/8urJ6yxjz/fKPt27fVkoBAJIKjDF/tPzZvzY2PE+IUIHS0AubPGVs+YdlBzeNMUoppACM1g4Tv7sBkVIqoaJIvZBS2WjGGABIIie5aW2M0f0R+2BpFkm1tdFgtLlHdFolpet6vk+EEPdDxrhZrzcwbmLcpJTdAzkqQzbtqOIaUlMbrfvulZBBa4/63R88SiSo1BRKBozeNXIYhIwxHg9WZIaUhtR64nsp3/M5F4xxAN1LjgdRpM2UUtKYq5SKu6jjr1HDbV8z5PsA0h3cGeas2Y+UguA/QGuwxymlMhPWWR1NJXMZAdBEUKlEvE4JGGeMsXR+esk2GY8Fq+56KGjPlLfb7e3tuuNgQojn+WnwLjJoX9AtUg8Ey5YAXNdrNBzHwRg3m81WL1mDDgVlUgCAx4lUMruP0nZx57TTw7rrseM+Zgpmi35nhjsJ6yx2dKL0faGUUUqllP3DYJ+qu25Vq9Xa3NxuNByMm67rEUK6fxKMSsG4EjuTgyBst9ue5wdBQAixy5BiKww3fF+q3qSQVEoqxYWkjFPGo4cufmutX6por+fX63OrW6knlf+3GOXazOjZmeNn3jh25s3R2kx5eqZci6Q0faE0fcHqJ6w+nejHXztv/WjgYBntKz069VJ+ahI9cmLwsWdQvor2l1Cugp4ci5SDo6hQRY+fRAfKaH9p8ImTA7kxdKAUmbuKEyhX2Vd7q1A7N5AbGz58avjoC0PFiYeePv1w+ZUHnnpu9+HJvUeeHypODB8aHzpyeu+zrz/44rld5anhfAWhfAXlK3tGJnaPnLI6Kk6iQnVoZHxPcXKwUE2cVgpVdGg8kZSM8mOxxHquT+mSgVis+Q8KvpmKsFvPzwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Home Page Light Theme",
            "title": "Home Page Light Theme",
            "src": "/static/2a029560ff59d44babc1ba086d2cb591/0eb09/mokens-light.png",
            "srcSet": ["/static/2a029560ff59d44babc1ba086d2cb591/c59a0/mokens-light.png 125w", "/static/2a029560ff59d44babc1ba086d2cb591/86700/mokens-light.png 250w", "/static/2a029560ff59d44babc1ba086d2cb591/0eb09/mokens-light.png 500w", "/static/2a029560ff59d44babc1ba086d2cb591/e9985/mokens-light.png 750w", "/static/2a029560ff59d44babc1ba086d2cb591/1263b/mokens-light.png 1000w", "/static/2a029560ff59d44babc1ba086d2cb591/ac7a9/mokens-light.png 1920w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Expanded Card Details - Light Theme
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ad30dd523ac6ea8e659a5e660b04d172/ac7a9/mokens-light-card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAADFUlEQVQ4y6WSW0iTYRjHn+m0NM9hZZqn6TSdurFZzURnOnOlrdVFVBfLpIwoKio60LbLhHASTS86wNouxMJyalBb6JqHsqIi6yKEEIoyalb7zn7fty/eaUhWV/758fDngR8vvO8LsZt3hysNUKibR7YFirb+QUk9KPQhts3N4nq07xt67vKOubxj/b6nLu9Yz+ATl3es99HThfgQfb5ns8wuQVhEkHzi+EmNWt12/miNRqOQq169fMXMsIEARlE0QVIESVI0TTMzNDPDshzH8RzHzxYkV1ZVl0pS3zhbDOVKAPD5fEIwOO2fJgiSCoVhGBaFCwbnzgyGGpJ1unp1vuT59YuHd9YBwOjwsCAI3wgSwzCCQCbP88HfWSjrGwyx0XHWk43Nuxri41MeD4+ygjD51f/1m9/vnw4EMBwnMAzHcYLn+YXy8VPnqnWGA/v2NzUdMh45+Hp8HJ1MUt9xIvAzgIeCYThJkhz3l6y/1B1/wJZ2yLa62bbqxOWRiQ+CIHwnyADNsMF/3/O8PDQxZR9+1/NisuvZe8fIhB+jSZadwgiKZv73SHPyDMcJQvATRd/6+OVtABcEgeO5Hwz7mWYp9Coc+y84ZAmL+yTtna4z1msWm93S4TC33zTZ7KZ2h8lmR73D8SdOU7vDbLOfsV519nqQbOlw7jnb0mSxGs1tRpPVaGpF5UKr0RzamNuMlhDmudlosu46ffFKZy+S79z3dPc/7Ozx9LoHXO5B14PQnC0eL+LBwPzSPdjjHnB5Bm/fc9/ougsQlhSTvmaVPAOWpIiiUyFRArGZEJ8Ny/MgJhP1pFxIzoOYDIhKg8iVEJEMogSISIKlyQArpJLmY3ttFrFEIZaWRZTqwgsqo0pqVlTsSEBds0xVFymvEa8tF0vVEet0KXpjbJUhLEcpTi8ESJNFF1cnbtgulqohpwzktVCsFRdp49bro1QNIKsNk2lFazWQXwHSjaDQwrotoNSCRAmpBQAZclGWQpStgtwyyKuAfA0U1kBxHcjrQd6AZkkdyLRQsAnyKyFHLcpSQbocsaboF9vnzVn088cpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Expanded Card Details Light Theme",
            "title": "Expanded Card Details Light Theme",
            "src": "/static/ad30dd523ac6ea8e659a5e660b04d172/0eb09/mokens-light-card.png",
            "srcSet": ["/static/ad30dd523ac6ea8e659a5e660b04d172/c59a0/mokens-light-card.png 125w", "/static/ad30dd523ac6ea8e659a5e660b04d172/86700/mokens-light-card.png 250w", "/static/ad30dd523ac6ea8e659a5e660b04d172/0eb09/mokens-light-card.png 500w", "/static/ad30dd523ac6ea8e659a5e660b04d172/e9985/mokens-light-card.png 750w", "/static/ad30dd523ac6ea8e659a5e660b04d172/1263b/mokens-light-card.png 1000w", "/static/ad30dd523ac6ea8e659a5e660b04d172/ac7a9/mokens-light-card.png 1920w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Card Design Proposal
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b88a3a7996ad1f3228c6c3319d74bc99/201a0/mokens-card-design.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.199999999999996%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaklEQVQoz3WQzUsCURTF/W/at61VtWhh0SIKgihy1UYRWhW1MUiKiMzCjybQMjPwI4wgWiSi0WS7CM1pETGQ1ITjx+jMe/PejZmJcAY6q8fl/u4559kAoNVuf34JCKFGsykI36qqgi5KKQCIYqNSLnNVjhCCCYYe2QjAxuqywz4cCDHelaU5+0gmk2V8OzzPF/K5y0zyNJ5YGB1MhPwKJjLqmmAKkD4/C/s2bwv5VOyY8W/dsazX46l91LLp9AlzGAwfTQ4NUEmUujJGZmctHgDpGVE9LqUU6/mr3GuRLQGAqhKjiAlmi4VkLMo+POZvruPRSL0uGieM8ixb2l1fi4QCCsJ/3/ELqxScjnn3zNi2b29xetw1O/X2zhswxlrI/YPgRH9f5T4nY9LpSFbnF44TWi0FoafnsiQrRhE9p+Zzkb1yO10AtItlpCIrrO1RSoiGKAhZiiGMFITBNOuBCSGgA9rjH1kuGvAPDM7fQCvgPI0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Card Design Proposal",
            "title": "Card Design Proposal",
            "src": "/static/b88a3a7996ad1f3228c6c3319d74bc99/0eb09/mokens-card-design.png",
            "srcSet": ["/static/b88a3a7996ad1f3228c6c3319d74bc99/c59a0/mokens-card-design.png 125w", "/static/b88a3a7996ad1f3228c6c3319d74bc99/86700/mokens-card-design.png 250w", "/static/b88a3a7996ad1f3228c6c3319d74bc99/0eb09/mokens-card-design.png 500w", "/static/b88a3a7996ad1f3228c6c3319d74bc99/e9985/mokens-card-design.png 750w", "/static/b88a3a7996ad1f3228c6c3319d74bc99/1263b/mokens-card-design.png 1000w", "/static/b88a3a7996ad1f3228c6c3319d74bc99/201a0/mokens-card-design.png 3777w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      